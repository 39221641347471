import { GoToResult } from '../GoToResult';

import iconIdea from '@/img/icons/cortex/icon-idea.svg';

import { useCortex } from './useCortex';
import type { CortexSearchResponse } from '@/api/CortexApi';
import type { GoToResultRecentKey } from '../GoToResult';
import type { Provider } from '../Provider';

/**
 * @description Provider class implemented as a composable. Due to how cortex functions we need to
 * call a composable method when the search term is updated. Since the Provider class pattern
 * is only used in a composable, the Provider itself can be a composable which allows for using
 * other composables.
 */
export const useCortexSection = (): Provider => {
  const cortex = useCortex();
  const buildCortexResult = (searchTerm: string, response: CortexSearchResponse) : GoToResult => {
    const { type } = response.results[0];
    const title = ['dsl', 'fts'].includes(type)
      ? type.toUpperCase()
      : `${type[0].toUpperCase()}${type.slice(1)}`;

    return new GoToResult({
      title,
      subtitle: searchTerm,
      imageComponent: iconIdea,
      recentKey: {
        type: `cortex:${response.results[0].type}`,
        id: searchTerm,
      },
    });
  };

  const lookup = async (recentKey: GoToResultRecentKey) : Promise<GoToResult | undefined> => {
    if (recentKey.type.includes('cortex')) {
      const [,type] = recentKey.type.split(':');
      return buildCortexResult(recentKey.id, {
        results: [{
          type,
        }],
      } as CortexSearchResponse);
    }

    return undefined;
  };

  const updateSearchTerm = async (searchTerm: string): Promise<GoToResult[]> => {
    if (searchTerm === '') {
      return [];
    }

    await cortex.askQuestion(searchTerm);

    return cortex.cortexResponse.value
      ? [buildCortexResult(searchTerm, cortex.cortexResponse.value)]
      : [];
  };

  return {
    lookup,
    updateSearchTerm,
  };
};
