<template>
  <section class="CortexPanel jc-scrollbar jc-scrollbar-vertical">
    <ButtonBack
      v-if="hasError"
      class="backButton"
      @click="clearPrompt"
    />

    <template v-if="cortexResponse && !hasError">
      <header
        class="header"
        :data-test-id="$testId('header')"
      >
        <ParagraphBody
          v-if="!cortexResponseErrored && !(isFts || isWorkflow)"
          class="headerMessage"
        >
          <IconGenAi
            class="cortexIcon"
            role="presentation"
          />
          <MarkdownInline :mdTemplate="headerMessage" />
        </ParagraphBody>
        <div
          v-if="!cortexResponseErrored && !(isFts || isWorkflow)"
          class="feedbackControls"
        >
          <button
            aria-label="Rate this response +1"
            class="feedbackButton"
            :data-test-id="$testId('buttonThumbsUp')"
            :disabled="feedbackScore !== undefined"
            @click="submitFeedback(cortexResponse.uuid, 1)"
          >
            <IconThumbsUp
              :class="['feedbackIcon', {
                'feedbackIcon--highlight': feedbackScore === 1,
                'feedbackIcon--disabled': feedbackScore === -1,
              }]"
              role="presentation"
            />
          </button>
          <button
            aria-label="Rate this response -1"
            class="feedbackButton"
            :data-test-id="$testId('buttonThumbsDown')"
            :disabled="feedbackScore !== undefined"
            @click="submitFeedback(cortexResponse.uuid, -1)"
          >
            <IconThumbsDown
              :class="['feedbackIcon', {
                'feedbackIcon--highlight': feedbackScore === -1,
                'feedbackIcon--disabled': feedbackScore === 1,
              }]"
              role="presentation"
            />
          </button>
        </div>
      </header>

      <CortexDslView
        v-if="isDsl"
        :data-test-id="$testId('dslView')"
      />

      <MarkdownDocument
        v-if="isFts || isWorkflow"
        :source="`\`\`\`json\n${JSON.stringify(cortexResponse, null, 2)}\n\`\`\``"
      />

      <CortexSupportView v-if="isSupport" />
    </template>

    <CortexSuggestions
      v-else-if="hasError"
      :data-test-id="$testId('suggestions')"
    />

    <CortexLander
      v-else
      :data-test-id="$testId('lander')"
    />

    <LoadingOverlay :visible="isSchemaLoading || isCortexLoading || isSearchLoading" />
  </section>
</template>

<script setup lang="ts">
import {
  ButtonBack,
  LoadingOverlay,
  MarkdownInline,
  ParagraphBody,
} from '@jumpcloud/ui-components';
import { computed, onBeforeMount } from 'vue';
import { useCortex } from './useCortex';
import CortexDslView from './CortexDslView.vue';
import CortexLander from './CortexLander.vue';
import CortexSuggestions from './CortexSuggestions.vue';
import CortexSupportView from './CortexSupportView.vue';
import IconGenAi from '@/img/icons/cortex/icon-gen-ai.svg';
import IconThumbsDown from '@/img/icons/cortex/icon-thumbs-down.svg';
import IconThumbsUp from '@/img/icons/cortex/icon-thumbs-up.svg';
import MarkdownDocument from './MarkdownDocument';
import cortexEventBus from './cortexEventBus';

const {
  clearErrors,
  fetchSearchSchema,
  submitFeedback,
  getFeedback,

  rationale,
  output,
  isDsl,
  isFts,
  isSupport,
  isWorkflow,
  cortexResponse,
  hasError,
  cortexResponseErrored,
  isCortexLoading,
  isSchemaLoading,
  isSearchLoading,
} = useCortex();

const headerMessage = computed(() => (
  isDsl.value
    ? output.value
    : rationale.value
));

const feedbackScore = computed(() => getFeedback(cortexResponse.value.uuid));

const clearPrompt = () => {
  cortexEventBus.publish('cortex:update-prompt', '');
  clearErrors();
};

onBeforeMount(() => {
  fetchSearchSchema();
});
</script>

<script lang="ts">
export default {
  name: 'CortexPanel',
};
</script>

<style scoped>
.CortexPanel {
  background-color: var(--jc-color-background);
  height: 100%;
  padding: var(--jc-spacer);
  position: relative;
}

.backButton {
  margin-bottom: var(--jc-spacer);
}

.header, .headerMessage, .feedbackControls {
  display: flex;
  gap: var(--jc-spacer-small);
}

.headerMessage {
  margin: 0 var(--jc-spacer) 0 0 !important;
}

.header {
  margin-bottom: var(--jc-spacer);
}

.cortexIcon {
  align-self: flex-start;
  display: inline-block;
  flex-shrink: 0;
  height: 24px;
  margin-right: var(--jc-spacer-small);
  margin-top: 6px;
  width: 48px;
}

.feedbackControls {
  align-items: center;
  margin-left: auto;
}

.feedbackButton {
  background-color: transparent;
  cursor: pointer;
  padding: var(--jc-spacer-x-small);
}

.feedbackButton:focus {
  box-shadow: var(--jc-focus-outline);
}

.feedbackIcon--highlight {
  color: var(--jc-color-primary-fill-default);
}

.feedbackIcon--disabled {
  color: var(--jcGrey400);
}
</style>
