import CortexApi from '@/api/CortexApi';
import SearchApi from '@/api/SearchApi';

const flattenSchema = (schema) => schema.objects.reduce((acc, entry) => {
  // eslint-disable-next-line no-param-reassign
  acc[entry.name] = entry;

  return acc;
}, {});

export default {
  namespaced: true,

  state: {
    isSchemaLoading: false,
    schemaErrored: false,
    schema: null,

    isCortexLoading: false,
    cortexErrored: false,
    cortexResponse: null,

    responseFeedback: {},
    isFeedbackLoading: false,
    feedbackErrored: false,

    isSearchLoading: false,
    searchErrored: false,
    searchResponse: null,
  },

  getters: {
    cortexResults(state) {
      return state.cortexResponse?.results || [];
    },

    cortexResultType(_, getters) {
      return getters.cortexResults[0]?.type;
    },

    cortexResponseErrored(_, getters) {
      return getters.cortexResults[0]?.error ?? false;
    },

    isDsl(_, getters) {
      return getters.cortexResultType === 'dsl';
    },

    isFts(_, getters) {
      return getters.cortexResultType === 'fts';
    },

    isSupport(_, getters) {
      return getters.cortexResultType === 'support';
    },

    isWorkflow(_, getters) {
      return getters.cortexResultType === 'workflow';
    },

    itemSchema(state, getters) {
      if (!getters.itemType) return null;

      return state.schema[getters.itemType] || null;
    },

    itemType(state, getters) {
      if (!getters.isDsl || !getters.cortexResults.length) return null;

      const { fields } = getters.cortexResults[0].result;

      if (!fields?.include.length) return null;

      // split the first field to get the item type
      // e.g. 'device.id' -> ['device', 'id']
      const [itemType] = fields.include[0].split('.');

      return itemType;
    },

    getFeedback(state) {
      return (uuid) => state.responseFeedback[uuid];
    },
  },

  actions: {
    async askQuestion({ commit }, query) {
      commit('SET_IS_CORTEX_LOADING', true);

      try {
        const res = await CortexApi.search(query);

        commit('SET_CORTEX_RESPONSE', res);
      } catch {
        commit('SET_CORTEX_ERRORED', true);
      } finally {
        commit('SET_IS_CORTEX_LOADING', false);
      }
    },

    async submitFeedback({ commit }, { uuid, score }) {
      commit('SET_IS_FEEDBACK_LOADING', true);

      try {
        await CortexApi.feedback(uuid, score);

        commit('SAVE_FEEDBACK', { uuid, score });
      } catch {
        commit('SET_FEEDBACK_ERRORED', true);
      } finally {
        commit('SET_IS_FEEDBACK_LOADING', false);
      }
    },

    async fetchSearchResults({ commit, getters }) {
      if (!getters.cortexResults.length || !getters.cortexResults[0].type) return;

      commit('SET_IS_SEARCH_LOADING', true);

      try {
        const res = await SearchApi.query(getters.cortexResults[0].result);

        commit('SET_SEARCH_RESPONSE', res);
      } catch {
        commit('SET_SEARCH_ERRORED', true);
      } finally {
        commit('SET_IS_SEARCH_LOADING', false);
      }
    },

    async fetchSearchSchema({ commit }) {
      commit('SET_IS_SCHEMA_LOADING', true);

      try {
        const res = await SearchApi.schema();

        commit('SET_SCHEMA', res);
      } catch {
        commit('SET_SCHEMA_ERRORED', true);
      } finally {
        commit('SET_IS_SCHEMA_LOADING', false);
      }
    },

    clearErrors({ commit, getters }) {
      commit('SET_CORTEX_ERRORED', false);
      commit('SET_FEEDBACK_ERRORED', false);
      commit('SET_SEARCH_ERRORED', false);
      commit('SET_SCHEMA_ERRORED', false);

      if (getters.cortexResponseErrored) {
        commit('SET_CORTEX_RESPONSE', null);
      }
    },
  },

  mutations: {
    // schema
    SET_SCHEMA(state, schema) {
      state.schema = flattenSchema(schema);
    },
    SET_IS_SCHEMA_LOADING(state, isLoading) {
      state.isSchemaLoading = isLoading;
    },
    SET_SCHEMA_ERRORED(state, errored) {
      state.schemaErrored = errored;
    },

    // cortex
    SET_CORTEX_RESPONSE(state, response) {
      state.cortexResponse = response;
    },
    SET_IS_CORTEX_LOADING(state, isLoading) {
      state.isCortexLoading = isLoading;
    },
    SET_CORTEX_ERRORED(state, errored) {
      state.cortexErrored = errored;
    },

    // search
    SET_SEARCH_RESPONSE(state, response) {
      state.searchResponse = response;
    },
    SET_SEARCH_ERRORED(state, errored) {
      state.searchErrored = errored;
    },
    SET_IS_SEARCH_LOADING(state, isLoading) {
      state.isSearchLoading = isLoading;
    },

    // feedback
    SAVE_FEEDBACK(state, { uuid, score }) {
      state.responseFeedback[uuid] = score;
    },
    SET_IS_FEEDBACK_LOADING(state, isLoading) {
      state.isFeedbackLoading = isLoading;
    },
    SET_FEEDBACK_ERRORED(state, errored) {
      state.feedbackErrored = errored;
    },
  },
};
