<template>
  <div class="CortexDslView">
    <Card class="card">
      <template #body>
        <DataTable
          v-if="cortex.searchResponse && totalCount > 0"
          :columns="columns"
          :data-test-id="$testId('dataTable')"
          :rows="rows"
        />
        <div
          v-else
          class="noResults"
          :data-test-id="$testId('noResults')"
        >
          <HeadingMedium>No results found</HeadingMedium>
          <IconButton
            :data-test-id="$testId('clearPromptButton')"
            hasOutline
            icon="ideaBot"
            :scale="1.25"
            text="Try a different prompt"
            type="secondary"
            @click="cortexEventBus.publish('cortex:update-prompt', '')"
          />
        </div>
      </template>
      <template #footer>
        <ParagraphBody
          v-if="totalCount > 0"
          class="footerCopy"
          :data-test-id="$testId('footerCopy')"
        >
          {{ footerCopy }}
        </ParagraphBody>
      </template>
    </Card>
    <div class="controls">
      <!-- @todo: Buttons are for presentation only, will be wired up later -->
      <DropdownActions
        buttonText="Download"
        isDisabled
        :items="[]"
      />
      <ButtonSecondary
        isDisabled
        :text="`View In ${cortex.itemType.value} List`"
      />
      <ButtonSecondary
        isDisabled
        text="View In Reports"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  ButtonSecondary,
  Card,
  DropdownActions,
  HeadingMedium,
  IconButton,
  ParagraphBody,
} from '@jumpcloud/ui-components';
import { computed } from 'vue';
import { useCortex } from '@/components/GoTo/Cortex/useCortex';
import { useRouter } from 'vue-router';
import DataTable, { type DataTableColumn, type DataTableRow } from './DataTable.vue';
import cortexEventBus from '@/components/GoTo/Cortex/cortexEventBus';
import type { DslResult } from '@/api/CortexApi';

const cortex = useCortex();
const router = useRouter();

const maxVisibleItems = 10;
const results = computed(() => cortex.results.value as DslResult[]);
const searchApiLimit = computed(() => results.value?.[0]?.limit || 0);
const totalCount = computed(() => cortex.searchResponse.value?.results?.length || 0);

/**
 * @example
 * | Displaying 1 - 10 of 100 records
 * | Displaying 1 - 100 of 100 or more records
 */
const footerCopy = computed(() => (
  `Displaying ${Math.min(1, totalCount.value)} - ${Math.min(totalCount.value, maxVisibleItems)} of ${totalCount.value}${!!searchApiLimit.value && totalCount.value === searchApiLimit.value ? ' or more' : ''} records`
));

const idKey = computed(() => (cortex.itemType.value ? `${cortex.itemType.value}.id` : ''));
const idField = computed(() => (
  cortex.searchResponse.value?.results?.[0].fields
    .find(({ field }) => field === idKey.value)
));
const itemPath = computed(() => {
  if (!idField.value) return '';

  if (/(device|user)(?!_group)/.test(cortex.itemType.value)) return `/${cortex.itemType.value}s/${idField.value.value}/details`;

  if (/(device|user)_group/.test(cortex.itemType.value)) {
    // e.g. 'device_group' -> ['device', 'group']
    const [type] = cortex.itemType.value.split('_');

    return `/groups/${type}/${idField.value.value}/details`;
  }

  return '';
});

const columns = computed<DataTableColumn[]>(() => (
  !cortex.searchResponse.value || !cortex.schema.value
    ? []
    : (cortex.searchResponse.value?.metadata.schema || []).reduce((
      acc,
      { field: key },
    ) => {
      if (key !== idKey.value) {
        /**
         * for some search responses the schema may not be just a single item type
         * e.g. 'device' & 'device_disk_summary' - this pulls the schema based on the item type
         * for each field and maps the appropriate label to each column
         */
        const [itemType] = key.split('.');
        const itemSchema = cortex.schema.value[itemType];
        const field = itemSchema.fields
          .find(({ name }) => name === key);

        acc.push({
          key,
          label: field!.label,
        });
      }

      return acc;
    }, [] as DataTableColumn[])
));

const rows = computed<DataTableRow[]>(() => (
  (cortex.searchResponse.value?.results || []).slice(0, maxVisibleItems).reduce((
    results,
    { fields },
  ) => {
    results.push(fields.reduce((row, { field, value }) => {
      if (field !== idKey.value) {
        // eslint-disable-next-line no-param-reassign
        row[field] = value ?? '—';
      }

      return row;
    }, {
      ariaLabel: idField.value
        ? `Click to view ${cortex.itemType.value}`
        : '',
      onClick: idField.value
        ? (_: DataTableRow) => {
          router.push({
            path: itemPath.value,
          });
          cortexEventBus.publish('cortex:update-route');
        }
        : null,
    } as DataTableRow));

    return results;
  }, [] as DataTableRow[])
));
</script>

<script lang="ts">
export default { name: 'CortexDslView' };
</script>

<style scoped>
.footerCopy {
  font-size: var(--jcBodySmall);
  font-style: italic;
  text-align: right;
}

.card {
  box-shadow: var(--jc-box-shadow);
  margin-bottom: var(--jc-spacer);
}

.controls {
  display: flex;
  gap: var(--jc-spacer);
  justify-content: flex-end;
}

.noResults {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: center;
}

.noResults__img {
  max-width: 300px;
}
</style>
