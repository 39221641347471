<template>
  <GoToModalWrapper
    :showModal="showModal"
    @close="closeModal"
  >
    <template v-slot:header>
      <div class="header">
        <GoToModalInput
          class="input"
          :resultCount="dataDrivenResultCount"
          :showModal="showModal"
          @update:input="onInput"
        />

        <ButtonClose
          v-if="hasFeatureFlag('cortexRelease')"
          tabindex="-1"
          @click="closeModal"
        />
      </div>
    </template>

    <template v-slot:body>
      <div
        ref="topElementRef"
        :class="['body', {
          splitPanel: hasFeatureFlag('cortexRelease')
        }]"
      >
        <div
          class="searchResults jc-scrollbar jc-scrollbar-vertical"
        >
          <template
            v-for="(section, sectionIndex) in resultSections"
          >
            <div
              v-if="section.visibleResults.length > 0"
              :key="sectionIndex"
              class="resultSection"
            >
              <div
                class="sectionHeader"
                :data-test-id="$testId('header-' + dashed(section.title))"
              >
                {{ section.title }}
              </div>
              <ul class="results">
                <li
                  v-for="item in section.visibleResults"
                  :key="item.visibleIndex"
                  :ref="el => setItemRef(el, item)"
                  :class="['resultItem', {
                    highlightedResult: isItemSelected(item)
                  }]"
                  :data-test-id="$testId('result-' + dashed(section.title))"
                  @click="onLinkClick(section, item)"
                  @mousemove="selectItem(item)"
                >
                  <div>
                    <component
                      :is="item.imageComponent"
                      class="icon"
                    />
                    <div
                      class="resultText"
                    >
                      <span
                        class="title"
                      >
                        {{ item.title }}
                      </span>
                      <span
                        v-if="item.subtitle"
                        class="subtitle"
                      >
                        <span class="divider">|</span>{{ item.subtitle }}
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </template>
        </div>

        <CortexPanel
          v-if="hasFeatureFlag('cortexRelease')"
          :data-test-id="$testId('cortexPanel')"
        />
      </div>
    </template>

    <template v-slot:footer>
      <div
        class="footer"
        :data-test-id="$testId('footer')"
      >
        Not finding what you need?
        <!--
        tabindex=-1 prevents link from being tabbable so shift+tab
        does not lose focus on input.
      -->
        <a
          :href="supportHref"
          tabindex="-1"
          target="_blank"
          @click="onSupportFooterClicked"
        >
          Search our Help Center!
        </a>
      </div>
    </template>
  </GoToModalWrapper>
</template>

<script>
import {
  ButtonClose,
  HeadingLarge,
  Icon,
  InputSearch,
  Link,
  ParagraphBody,
  StatusBadge,
} from '@jumpcloud/ui-components';

import { ref, toRefs } from 'vue';
import { useCortex } from './Cortex/useCortex';
import { useFeatureFlags } from '@/feature-flags';
import { useRouter } from 'vue-router';
import { useSections } from './useSections';
import { useSelectedIndex } from './useSelectedIndex';
import { useUiEvents } from './useUiEvents';
import CortexPanel from './Cortex/CortexPanel.vue';
import GoToModalInput from './GoToModalInput.vue';
import GoToModalWrapper from './GoToModalWrapper.vue';
import cortexEventBus from './Cortex/cortexEventBus';

export default {
  name: 'GoToModal',

  components: {
    ButtonClose,
    CortexPanel,
    GoToModalInput,
    GoToModalWrapper,
    HeadingLarge,
    Icon,
    InputSearch,
    Link,
    ParagraphBody,
    StatusBadge,
  },

  props: {
    showModal: Boolean,
  },

  emits: ['update:showModal'],

  setup(props, { emit }) {
    const { showModal } = toRefs(props);
    const search = ref('');

    const events = useUiEvents(search);
    const sections = useSections(search);
    const selectedIndex = useSelectedIndex(sections.visibleResultCount, showModal);
    const { hasFeatureFlag } = useFeatureFlags();

    let cortex;

    if (hasFeatureFlag('cortexRelease')) {
      cortex = useCortex();
    }

    const onInput = (input) => {
      search.value = input;
      selectedIndex.reset();
      sections.providersUpdateSearchTerm(input);

      if (!input && hasFeatureFlag('cortexRelease')) {
        cortex.setCortexResponse(null);
      }
    };

    const closeModal = (fromClick) => {
      // If the modal is closing for any reason other than a click, we want to
      // record that they navigated away without clicking.
      if (!fromClick) {
        events.triggerExitWithoutClickEvent();
      }

      cortex?.setCortexResponse(null);

      emit('update:showModal', false);
    };

    const router = useRouter();
    const onLinkClick = (section, item) => {
      if (item.externalUrl) {
        window.open(item.externalUrl, '_blank');
      } else if (hasFeatureFlag('cortexRelease') && item.recentKey.type.includes('cortex')) {
        cortexEventBus.publish('cortex:update-prompt', item.subtitle);
        sections.addRecent(item);
      } else {
        router.push(item.url);
        closeModal(true);
        // Add this last so the UI doesn't change until after it is closed
        sections.addRecent(item);
      }

      events.triggerLinkClickedEvent(section, item);
    };

    return {
      closeModal,
      cortex,
      dataDrivenResultCount: sections.dataDrivenResultCount,
      hasFeatureFlag,
      isItemSelected: selectedIndex.isSelected,
      onInput,
      onLinkClick,
      resultSections: sections.resultSections,
      selectItem: selectedIndex.set,
      supportHref: sections.supportHref,
      topElementRef: selectedIndex.topElementRef,
      triggerSupportFooterClickedEvent: events.triggerSupportFooterClickedEvent,
      visibleElementRefs: selectedIndex.visibleElementRefs,
    };
  },

  methods: {
    dashed(text) {
      return text.replaceAll(' ', '-');
    },

    onSupportFooterClicked() {
      this.triggerSupportFooterClickedEvent();
    },

    setItemRef(element, item) {
      // element will be null when the item is not visible, a qwirk of vue
      if (element) {
        this.visibleElementRefs[item.visibleIndex] = element;
      }
    },
  },
};
</script>

<style scoped>
:deep([class*="ModalContentStructure__body"]) {
  overflow-y: hidden !important;
}

.splitPanel {
  display: grid;
  gap: var(--jc-spacer);
  grid-template-columns: 320px 1fr;
  height: 100%;
}

.header {
  display: flex;
  gap: var(--jc-spacer);
}

.input {
  flex: 1;
}

.divider {
  color: var(--jc-border-color);
  padding-left: var(--jc-input-padding-side);
  padding-right: var(--jc-input-padding-side);
}

.footer {
  font-size: var(--jcBodySmall);
  font-weight: 400;
}

.footer a {
  font-weight: 600;
}

.sectionHeader {
  align-items: flex-end;
  color: var(--jc-text-color-light);
  display: flex;
  font-size: var(--jcBodySmall);
  font-weight: 600;
  height: var(--jcHeadingLarge);
  margin: 0;
  margin-bottom: var(--jc-spacer-small);
  padding-left: var(--jc-spacer-small);
}

.icon.icon {
  fill: currentColor;
  height: var(--jcHeadingMedium);
  margin-right: var(--jc-input-padding-side);
  vertical-align: middle;
  width: var(--jcHeadingMedium);
}

.resultItem {
  background-color: white;
  border: var(--jc-border-width) solid transparent;
  border-radius: var(--jc-border-radius);
  gap: var(--jc-spacer-x-small);
  padding: var(--jc-spacer-small);
  transition: background-color var(--jc-transition-duration-fast) var(--jc-transition-function),
              border-color var(--jc-transition-duration-fast) var(--jc-transition-function);
}

.highlightedResult {
  background-color: var(--jc-color-secondary-fill-hover);
  border: var(--jc-border);
  border-radius: var(--jc-border-radius);
  cursor: pointer;
}

.resultSection {
  margin-bottom: var(--jc-spacer);
  margin-top: var(--jc-spacer-small);
}

.resultText {
  display: inline;
  vertical-align: middle;
}

.searchResults {
  width: 100%;
}

.subtitle {
  color: var(--jc-text-color-light);
  font-size: var(--jcBody);
  font-weight: 400;
}

.title {
  color: var(--jc-text-color);
  display: inline-block;
  font: var(--jc-font);
  font-size: var(--jcBody);
  font-weight: 600;
}

.results {
  display: flex;
  flex-direction: column;
  gap: var(--jc-spacer-x-small);
  list-style-type: none;
  margin: 0;
  padding: 0;
}
</style>
