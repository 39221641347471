<template>
  <Card>
    <template #header>
      <HeadingMedium>Looking for something else?</HeadingMedium>

      <ParagraphBody class="headerCopy">
        You can easily get quick links to users, devices, and navigation items in the search area,
        but I can now process complex search strings to help you be more efficient and effective.
        Try one of these to get started:
      </ParagraphBody>
    </template>

    <template #body>
      <ul class="suggestions">
        <li
          v-for="prompt, i in suggestedPrompts"
          :key="i"
        >
          <button
            class="suggestionButton"
            @click="submitPrompt(prompt)"
          >
            {{ prompt }}
          </button>
        </li>
      </ul>
    </template>
  </Card>
</template>

<script setup lang="ts">
import { Card, HeadingMedium, ParagraphBody } from '@jumpcloud/ui-components';
import cortexEventBus from './cortexEventBus';

const suggestedPrompts = [
  'Get me a list of devices with 16GB of RAM',
  'Which devices have less than 100GB of disk space?',
  'Get me a list of installed software',
  'How do I create a new policy group?',
];

const submitPrompt = (prompt: string) => {
  cortexEventBus.publish('cortex:update-prompt', prompt);
};
</script>

<style scoped>
.headerCopy {
  margin: 0;
}

.suggestions {
  display: flex;
  flex-direction: column;
  gap: var(--jc-spacer-x-small);
  list-style: none;
  margin: 0;
  padding: 0;
}

.suggestionButton {
  background-color: transparent;
  cursor: pointer;
  padding: var(--jc-spacer-small) var(--jc-spacer-x-small);
}

.suggestionButton:focus {
  box-shadow: var(--jc-focus-outline);
}
</style>
