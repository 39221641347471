import { type AxiosInstance, createJcApiAxiosInstance } from '@jumpcloud-ap/axios-setup';

export interface SearchQueryRequestFields {
  exclude: string[];
  include: string[];
}

export interface SearchQueryRequestFilter {
  field: string | null;
  operation:
  | 'operation_unknown'
  | 'and'
  | 'or'
  | 'equals'
  | 'not_equals'
  | 'greater_than'
  | 'less_than'
  | 'starts_with'
  | 'ends_with';
}

export interface SearchQueryRequest {
  aggregations: string[];
  fields: SearchQueryRequestFields;
  filters: SearchQueryRequestFilter[];
  limit: number;
}

export interface SearchQuerySchemaField {
  field: string;
  format: string | null;
  function:
  | 'function_unknown'
  | 'count'
  | 'avg'
  | 'max'
  | 'min'
  | 'sum'
  | null;
  type:
  | 'type_unknown'
  | 'datetime'
  | 'boolean'
  | 'string'
  | 'number';
}

export interface SearchQueryMetadata {
  queryTime: number;
  schema: SearchQuerySchemaField[];
}

export interface SearchQueryResponseField {
  field: string;
  value: string;
}

export interface SearchQueryResponseResult {
  fields: SearchQueryResponseField[];
  itemnum: number;
}

export interface SearchQueryResponse {
  metadata: SearchQueryMetadata;
  results: SearchQueryResponseResult[]
}

export interface SearchSchemaField {
  description: string;
  name: string;
  type: string;
  sortable: boolean;
  aggregatable: boolean;
  filterable: boolean;
  popularity: number;
  label: string;
  valueset: string[];
  allowedUnits: string[];
}

export interface SearchSchemaObject {
  name: string;
  fields: SearchSchemaField[];
  description: string;
  label: string;
  joins: string[];
}

export interface SearchSchemaResponse {
  objects: SearchSchemaObject[];
}

class SearchApi {
  private baseUrl = '/api/v2/search';

  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = createJcApiAxiosInstance();
  }

  get queryUrl() {
    return `${this.baseUrl}/query`;
  }

  get schemaUrl() {
    return `${this.baseUrl}/query/schema`;
  }

  async query(body: SearchQueryRequest) {
    const res = await this.axiosInstance.post<SearchQueryResponse>(this.queryUrl, body);

    return res?.data;
  }

  async schema() {
    const res = await this.axiosInstance.get<SearchSchemaResponse>(this.schemaUrl);

    return res?.data;
  }
}

export default new SearchApi();
