/* eslint-disable @typescript-eslint/no-unused-vars */
import { createScopedEventBus } from '@jumpcloud-ap/event-bus';

export default createScopedEventBus('cortex', {
  'cortex:update-prompt': {
    callback: (_prompt: string) => {},
    shouldSaveState: false,
  },
  'cortex:update-route': {
    callback: () => {},
    shouldSaveState: false,
  },
});
