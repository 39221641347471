import { computed } from 'vue';
import { useFeatureFlags } from '@/feature-flags';
import type { CortexSearchResponse } from '@/api/CortexApi';
import type { SearchQueryResponse, SearchSchemaObject } from '@/api/SearchApi';
// @ts-expect-error - vuex types not working for some reason
import { useStore } from 'vuex';

export const useCortex = () => {
  const store = useStore();
  const { hasFeatureFlag } = useFeatureFlags();

  const isCortexLoading = computed<boolean>(() => store.state.CortexModule.isCortexLoading);
  const cortexResponse = computed<CortexSearchResponse>(
    () => store.state.CortexModule.cortexResponse,
  );
  const results = computed<CortexSearchResponse['results']>(() => cortexResponse.value?.results || []);
  // currently we only support 1 result from AI queries
  const isDsl = computed<boolean>(() => store.getters['CortexModule/isDsl']);
  const isFts = computed<boolean>(() => store.getters['CortexModule/isFts']);
  const isSupport = computed<boolean>(() => store.getters['CortexModule/isSupport']);
  const isWorkflow = computed<boolean>(() => store.getters['CortexModule/isWorkflow']);
  const rationale = computed<string>(() => cortexResponse.value?.rationale || '');
  const output = computed<string>(() => results.value[0]?.output || '');

  // NLI API error
  const cortexErrored = computed<boolean>(() => store.state.CortexModule.cortexErrored);
  // LLM error
  const cortexResponseErrored = computed<boolean>(() => store.getters['CortexModule/cortexResponseErrored']);
  // Search API error
  const searchErrored = computed<boolean>(() => store.state.CortexModule.searchErrored);
  const hasError = computed<boolean>(() => cortexErrored.value
    || cortexResponseErrored.value
    || searchErrored.value,
  );
  const clearErrors = (): Promise<void> => store.dispatch('CortexModule/clearErrors');

  const isSearchLoading = computed<boolean>(() => store.state.CortexModule.isSearchLoading);
  const searchResponse = computed<SearchQueryResponse>(
    () => store.state.CortexModule.searchResponse,
  );
  const fetchSearchResults = (): Promise<void> => store.dispatch('CortexModule/fetchSearchResults');

  const isSchemaLoading = computed<boolean>(() => store.state.CortexModule.isSchemaLoading);
  const schema = computed<Record<string, SearchSchemaObject>>(() => (
    store.state.CortexModule.schema
  ));
  const itemType = computed(() => store.getters['CortexModule/itemType']);
  const fetchSearchSchema = (): Promise<void> => store.dispatch('CortexModule/fetchSearchSchema');

  const askQuestion = async (query: string): Promise<void> => {
    await clearErrors();

    await store.dispatch('CortexModule/askQuestion', query);

    // @todo: this conditional can be removed once all FFs are enabled
    const shouldCallSearch = isDsl.value
      || (isWorkflow.value && hasFeatureFlag('cortexWorkflows'))
      || (isFts.value && hasFeatureFlag('cortexFts'));

    if (!cortexErrored.value && !cortexResponseErrored.value && shouldCallSearch) {
      await fetchSearchResults();
    }
  };
  const submitFeedback = (uuid: string, score: -1 | 1): Promise<void> => store.dispatch('CortexModule/submitFeedback', { uuid, score });
  const setCortexResponse = (newCortexResponse: CortexSearchResponse | null) => {
    store.commit('CortexModule/SET_CORTEX_RESPONSE', newCortexResponse);
  };
  const getFeedback = (uuid: string): -1 | 1 => store.getters['CortexModule/getFeedback'](uuid);

  return {
    isCortexLoading,
    cortexResponse,
    results,
    rationale,
    output,
    isDsl,
    isFts,
    isSupport,
    isWorkflow,
    askQuestion,
    setCortexResponse,
    submitFeedback,
    getFeedback,

    isSchemaLoading,
    schema,
    itemType,
    fetchSearchSchema,

    isSearchLoading,
    searchResponse,
    fetchSearchResults,

    cortexErrored,
    cortexResponseErrored,
    searchErrored,
    hasError,
    clearErrors,
  };
};
