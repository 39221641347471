import { type AxiosInstance, createJcApiAxiosInstance } from '@jumpcloud-ap/axios-setup';

export interface FtsResult {
  type: 'fts';
  result: {
    query: string;
  };
  output: string;
  error: boolean;
}

export interface DslResult {
  type: 'dsl';
  limit: number,
  result: {
    filters: unknown[];
    sort: unknown[];
    fields: unknown[];
  };
  output: string;
  error: boolean;
}

// Workflow results aren't functional yet
export interface WorkflowResult {}

export interface SupportDocument {
  content: string;
  created_at: string;
  deleted_at: string | null;
  embedding: string | null;
  id: number;
  relevancy: number;
  title: string;
  updated_at: string | null;
  url: string;
}

export interface SupportResult {
  type: 'support';
  output: string;
  result: {
    documents: SupportDocument[];
  }
  error: boolean;
}

export type CortexSearchResult =
| DslResult
| FtsResult
| SupportResult;

export interface CortexSearchResponse {
  // totalCount: number;
  uuid: string;
  rationale: string;
  results: CortexSearchResult[];
}

class CortexApi {
  private baseUrl = '/api/v2/nli';
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = createJcApiAxiosInstance();
  }

  get feedbackUrl() {
    return `${this.baseUrl}/feedback`;
  }

  get searchUrl() {
    return `${this.baseUrl}/query`;
  }

  feedback(uuid: string, score: number) {
    return this.axiosInstance.post(this.feedbackUrl, { uuid, score })
      .then(res => res.data);
  }

  search(query: string) : Promise<CortexSearchResponse> {
    return this.axiosInstance.post<CortexSearchResponse>(this.searchUrl, { query })
      .then((res) => res.data);
  }
}

const api = new CortexApi();
export default api;
