<template>
  <teleport to="body">
    <ModalStructure
      ref="modal"
      :class="['gotoModal', {
        gotoModalWide: enableCortex
      }]"
      :isFullScreen="isFullScreen"
      :shouldCloseOnBlur="!enableCortex"
      :showCloseButton="false"
      :showModal="showModal"
      @close="handleModalClosed"
    >
      <template #header>
        <slot name="header" />
      </template>
      <template #body>
        <slot name="body" />
      </template>
      <template #footer>
        <slot name="footer" />
      </template>
    </ModalStructure>
  </teleport>
</template>

<script>
import { ModalStructure } from '@jumpcloud/ui-components';
import { computed, ref, toRefs } from 'vue';
import { useFeatureFlags } from '@/feature-flags';
import { useResizeObserver } from './useResizeObserver';
import { useVisibilityListener } from './useVisibilityListener';
import cortexEventBus from './Cortex/cortexEventBus';

const fullScreenMaxWidth = 1200;

export default {
  name: 'GoToModalWrapper',

  components: {
    ModalStructure,
  },

  props: {
    showModal: Boolean,
  },

  emits: ['close'],

  setup(props, { emit }) {
    const { showModal } = toRefs(props);
    const { onVisible, onHidden } = useVisibilityListener(showModal);
    const { hasFeatureFlag } = useFeatureFlags();

    const isFullScreen = ref(false);
    const enableCortex = computed(() => hasFeatureFlag('cortexRelease'));

    if (enableCortex.value) {
      useResizeObserver(document.documentElement, (entry) => {
        isFullScreen.value = entry.contentRect.width < fullScreenMaxWidth;
      });

      cortexEventBus.subscribe('cortex:update-route', () => {
        emit('close');
      });
    }

    // methods
    const handleModalClosed = () => emit('close');

    const onKeyDown = (event) => {
      switch (event.key) {
        case 'Escape':
          // Prevent clearing the input before the modal visually disappears
          event.preventDefault();
          handleModalClosed();
          break;
        default:
      }
    };

    onVisible(() => {
      window.addEventListener('keydown', onKeyDown, true);

      // Set the modal top margin below the intercom banner if its present. Most modals are
      // centered vertically so this isn't a problem, but our top alignment means the banner can
      // cover the modal.
      const intercomBanner = document.querySelector('iframe[name="intercom-banner-frame"]');
      if (intercomBanner) {
        const bannerHeight = intercomBanner.offsetHeight;
        document.documentElement.style.setProperty('--goto-modal-margin-top', `${bannerHeight + 10}px`);
      }
    });

    onHidden(() => {
      window.removeEventListener('keydown', onKeyDown, true);
    });

    return {
      enableCortex,
      isFullScreen,
      handleModalClosed,
    };
  },
};
</script>

<style scoped>
.gotoModal:not([class*="ModalOverlay__fullScreen"]) :deep(.body) {
  max-height: min(500px, calc(100vh - 200px));
}

.gotoModal :deep(.body) {
  padding-top: var(--modal-padding);
}

.gotoModal:not([class*="ModalOverlay__fullScreen"]) :deep(div[class*="ModalContainer__modal"]) {
  margin-top: var(--goto-modal-margin-top, 60px);
}

.gotoModal :deep(div[class*="ModalContentStructure__modalContent"]) {
  --modal-padding: var(--jc-spacer);

  grid-gap: 0;
}

.gotoModal :deep(footer[class*="ModalContentStructure__footer"]) {
  border-top: var(--jc-border);
  padding-left: var(--jc-spacer-medium);
}

.gotoModal {
  align-items: flex-start!important;  /* overwrite default modal alignment */
  z-index: 2000 !important; /* to render above top nav tooltip */

  --modal-width: 500px;
}

.gotoModalWide {
  --modal-width: 1200px;
}

.gotoModalWide:not([class*="ModalOverlay__fullScreen"]) :deep([class*="ModalContainer__modal"]) {
  height: 70vh;
}

.gotoModalWide:not([class*="ModalOverlay__fullScreen"]) :deep(.body) {
  max-height: unset;
}
</style>
