<template>
  <div
    class="DataTable"
    role="table"
  >
    <div
      class="DataTable__header"
      role="rowgroup"
    >
      <div
        class="DataTable__headerRow"
        role="row"
        :style="gridRowStyle"
      >
        <span
          v-for="column, i in columns"
          :key="i"
          class="DataTable__heading"
          role="columnheader"
        >
          {{ column.label }}
        </span>
      </div>
    </div>
    <div
      class="DataTable__body"
      role="rowgroup"
    >
      <div
        v-for="row, i in rows"
        :key="i"
        :aria-label="row.ariaLabel || ''"
        :class="['DataTable__row', {
          'DataTable__row--clickable': !!row.onClick,
        }]"
        role="row"
        :style="gridRowStyle"
        @click="() => row.onClick?.(row)"
      >
        <span
          v-for="column, j in columns"
          :key="j"
          class="DataTable__cell"
          role="cell"
        >
          <slot
            v-if="$slots[column.key]"
            :name="`${column.key}`"
            :row="row"
          />
          <template v-else>
            {{ row[column.key] }}
          </template>
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { type CSSProperties, computed } from 'vue';

export interface DataTableColumn {
  key: string;
  label: string;
}

export type DataTableRow = { [key: string]: string | number } & {
  ariaLabel: string
  onClick?: (row: DataTableRow) => void
};

const props = defineProps<{
  columns: DataTableColumn[];
  rows: DataTableRow[];
}>();

const gridRowStyle = computed<CSSProperties>(() => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${props.columns.length}, 1fr)`,
}));
</script>

<style scoped>
.DataTable {
  font-size: var(--jcBody);
  width: 100%;
}

.DataTable__heading {
  font-weight: 600;
  padding: var(--jc-spacer-small) var(--jc-spacer);
}

.DataTable__row {
  border: 1px solid var(--jc-border-color);
  border-radius: var(--jc-border-radius);
  display: block;
  margin-bottom: var(--jc-spacer-small);
  width: 100%;
}

.DataTable__row--clickable {
  cursor: pointer;
  transition: background-color var(--jc-transition-duration);
}

.DataTable__row--clickable:hover {
  background-color: var(--jc-row-selected);
}

.DataTable__cell {
  overflow-wrap: anywhere;
  padding: var(--jc-spacer);
  pointer-events: none;
}
</style>
