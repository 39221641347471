import { GoToResult } from './GoToResult';
import {
  computed, reactive, ref, toRaw, unref, watch,
} from 'vue';
import { useCortexSection } from './Cortex/CortexSection';
import { useFeatureFlags } from '../../feature-flags';
import { useRecents } from './useRecents';
import PagesSection from './PagesSection';
import ResourcesSection from './ResourcesSection';
import iconSupport from '@/img/icons/nav/icon-nav-support.svg';
import type { Provider } from './Provider';
import type { Reactive, Ref } from 'vue';
import type { Section } from './Section';

function createSection(
  title: string,
  provider: Provider,
  maxVisible: number,
  maxVisibleNoSearch: number,
): Reactive<Section> {
  return reactive({
    title,
    provider,
    allResults: [],
    maxVisible,
    maxVisibleNoSearch,
    visibleResults: [],
  } as Section);
}

// Function to compute the total count of results
function useDataDrivenResultCount(sections: Reactive<Section>[]) {
  return computed(() => sections.reduce(
    (sum, section) => sum + section.allResults.length,
    0,
  ));
}

// Function to compute the support URL
function useSupportHref(input: Ref<string>) {
  return computed(() => (input.value === ''
    ? 'https://jumpcloud.com/support'
    : `https://jumpcloud.com/support/search?search=${encodeURI(input.value)}`));
}

// Function to create the help center section
function createHelpCenterSection(
  input: Ref<string>,
  dataDrivenResultCount: Ref<number>,
  supportHref: Ref<string>,
): Reactive<Section> {
  return reactive({
    title: 'Search Help Center',
    visibleResults: computed(() => {
      if (dataDrivenResultCount.value === 0) {
        return [new GoToResult({
          title: input.value,
          subtitle: '',
          keys: '',
          imageComponent: iconSupport,
          externalUrl: supportHref.value,
        })];
      }
      return [];
    }),
    maxVisible: 1,
  } as Section);
}

// Function to watch for new visible results in all the sections, and update the visibleIndex
// and visibleResultCount when anything else changes.
function watchForNewVisibleResults(sections: Reactive<Section>[]) {
  const visibleResultCount = ref(0);
  watch(
    () => sections.map(section => section.visibleResults),
    () => {
      let totalIndex = 0;
      sections.forEach(section => {
        unref(section.visibleResults).forEach((_, index, sectionVisibleResults) => {
          toRaw(sectionVisibleResults[index]).visibleIndex = totalIndex;
          totalIndex += 1;
        });
      });
      visibleResultCount.value = totalIndex;
    },
    { deep: true },
  );
  return visibleResultCount;
}

// Helper function to filter results that are based on a feature flag that is not enabled
function filterResultsForFeatureFlags(results: GoToResult[]) {
  const { hasFeatureFlag } = useFeatureFlags();

  return results.filter((e) => {
    if (!e.featureFlag) {
      return true;
    }

    // @ts-expect-error Assume this is a flag
    const hasFlag = hasFeatureFlag(e.featureFlag);

    return e.featureFlag.startsWith('!') === !hasFlag;
  });
}

export function useSections(
  input: Ref<string>,
) {
  const { hasFeatureFlag } = useFeatureFlags();
  const pagesSection = createSection('Pages', PagesSection, 20, 7);
  const resourcesSection = createSection('Resources', ResourcesSection, 10, 0);

  const dataDrivenSections = [resourcesSection, pagesSection];

  if (hasFeatureFlag('cortexRelease')) {
    const cortexSection = createSection('Cortex', useCortexSection(), 10, 0);

    dataDrivenSections.unshift(cortexSection);
  }

  // Sum up all the sections so we have a total count
  const dataDrivenResultCount = useDataDrivenResultCount(dataDrivenSections);
  const recentsSection = useRecents(input, dataDrivenSections);

  const supportHref = useSupportHref(input);
  const helpCenterSection = createHelpCenterSection(input, dataDrivenResultCount, supportHref);

  const resultSections = [
    recentsSection,
    ...dataDrivenSections,
    helpCenterSection,
  ];

  const visibleResultCount = watchForNewVisibleResults(resultSections);

  const providersUpdateSearchTerm = (searchTerm: string) => {
    for (const section of dataDrivenSections) {
      section.provider?.updateSearchTerm(searchTerm).then(results => {
        const filteredResults = filterResultsForFeatureFlags(results);
        section.allResults = filteredResults;
        section.visibleResults = filteredResults.slice(
          0,
          searchTerm === '' ? section.maxVisibleNoSearch : section.maxVisible,
        );
      });
    }
  };

  return {
    addRecent: recentsSection.add,
    dataDrivenResultCount,
    providersUpdateSearchTerm,
    resultSections,
    supportHref,
    visibleResultCount,
  };
}
